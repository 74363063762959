import React from 'react'
import {Link} from 'gatsby'

import {rhythm, scale} from '../utils/typography'
import styled, { createGlobalStyle } from 'styled-components';
import { primaryColor } from '../style/common';

const H1 = styled.h1`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 400;
  margin-top: 0;
  margin-bottom: 0;
`;

const H3 = styled.h3`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 400;
  margin-top: 0;
`;

const Wrapper = styled.div`
  box-sizing: border-box;
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
`;

const GlobalStyle = createGlobalStyle`
  * {
    font-family: Montserrat;
  }
`

class Layout extends React.Component {
  render() {
    const { location, title, children, menuLinks } = this.props
    const rootPath = `/`
    let header

    if (location.pathname === rootPath) {
      header = (
        <H1>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </H1>
      )
    } else {
      header = (
        <H3>
          <Link
            style={{
              boxShadow: `none`,
              textDecoration: `none`,
              color: `inherit`,
            }}
            to={`/`}
          >
            {title}
          </Link>
        </H3>
      )
    }
    return (
      <Wrapper>
        <GlobalStyle />
        <div>
          <nav>
            <ul style={{ display: "flex", flex: 1, listStyleType: 'none' }}>
              {menuLinks.map((link, index) => (
                <li
                  key={link.name}
                  style={{
                    listStyleType: `none`,
                    margin: `1rem`,
                    marginLeft: index ? '1rem' : 0,
                    textTransform: 'uppercase',
                    fontSize: 14,
                  }}
                >
                  <Link to={link.link} style={{color: primaryColor, textDecoration: 'none', boxShadow: 'none'}}>
                    {link.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </div>
        {header}
        {children}
        <footer>
          © {new Date().getFullYear()}, Friedolin Förder
        </footer>
      </Wrapper>
    )
  }
}

export default Layout
