import React from "react";
import { graphql } from "gatsby";
import MDXRenderer from "gatsby-mdx/mdx-renderer";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import { rhythm } from "../utils/typography";
import styled from "styled-components";

const H1 = styled.h1`
  font-family: Montserrat;
  font-size: 42px;
  font-weight: 900;
`;

class BlogPostAmpTemplate extends React.Component {
  render() {
    const post = this.props.data.mdx;
    const siteTitle = this.props.data.site.siteMetadata.title;
    const { previous, next } = this.props.pageContext;

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        menuLinks={this.props.data.site.siteMetadata.menuLinks}
      >
        <SEO title={post.frontmatter.title} description={post.excerpt} />
        <H1>{post.frontmatter.title}</H1>
        <MDXRenderer>{post.code.body}</MDXRenderer>
        <hr
          style={{
            marginBottom: rhythm(1)
          }}
        />
      </Layout>
    );
  }
}

export default BlogPostAmpTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    site {
      siteMetadata {
        title
        menuLinks {
          name
          link
        }
        author
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      frontmatter {
        title
      }
      code {
        body
      }
    }
  }
`;
